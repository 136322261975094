<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini">
      <template #buttons>
        <el-button type="success" size="mini" @click="itemAdd">新增印章</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      :data="tableDataMain"
      :columns="tableColumn"
      :max-height="this.$util.getViewHeight() - 150"
    >
      <template #default_is_open_str="{ row }">
        <span :class="row.is_open ? 'c-green' : 'c-red'">{{ row.is_open_str }}</span>
      </template>
      <template #default_tu_pian="{ row }">
        <e-upload-image v-if="row.tu_pian" size="30px" disabled :list.sync="row.tu_pian" />
      </template>
      <template #default_operate="{ row }">
        <template>
          <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
        </template>
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 地址增改弹窗 -->
    <PopupSeal
      v-if="isShowPopupSeal"
      :show.sync="isShowPopupSeal"
      :data.sync="itemObj"
      @success="getListData"
    />
  </div>
</template>

<script>
import PopupSeal from './popup-seal'
export default {
  components: { PopupSeal },
  data() {
    return {
      keyword: '',
      filterData: {},
      sortData: {
        id: 'asc'
      },
      tableColumn: [
        {
          field: 'id',
          title: 'ID'
        },
        {
          field: 'name',
          title: '印章名称'
        },
        {
          field: 'yin_zhang_type',
          title: '印章类型'
        },
        {
          field: 'is_open_str',
          title: '状态',
          slots: { default: 'default_is_open_str' }
        },
        {
          field: 'tu_pian',
          title: '缩略图',
          slots: { default: 'default_tu_pian' }
        },
        {
          field: 'bei_zhu',
          title: '备注'
        },
        { title: '操作', width: 160, slots: { default: 'default_operate' } }
      ],
      tableDataMain: [],
      pageOptions: {
        ...this.$constant.page
      },
      isShowPopupSeal: false,
      itemDefault: {
        id: '',
        name: '',
        yin_zhang_type: '',
        is_open: false,
        tu_pian: [],
        bei_zhu: ''
      },
      itemObj: {}
    }
  },
  created() {
    this.initItemObj()
    this.getListData()
  },
  methods: {
    // 初始化itemObj
    initItemObj() {
      this.itemObj = this.$util.deepCopy(this.itemDefault)
    },
    // 获取数据列表
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/yinZhang/search',
        data: {
          keyword: this.keyword,
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          sortData: this.sortData,
          filterData: this.filterData
        }
      }).then((res) => {
        if (res.status === 200) {
          this.tableDataMain = res.data.list
          this.pageOptions.pageSize = res.data.pageSize
          this.pageOptions.total = res.data.totalRow
          this.pageOptions.currentPage = res.data.pageNumber
        }
      })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupSeal = true
      this.initItemObj()
    },
    // 项-编辑
    itemEdit(row) {
      this.isShowPopupSeal = true
      this.itemObj = this.$util.deepCopy(row)
    },
    // 项-删除
    itemDelete(row) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          // 传送删除动作
          this.$api({
            method: 'post',
            url: '/admin/gcvip/yinZhang/delete',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
